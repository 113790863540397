import { CURRENCIES } from '@/utils/i18n/constants';
import useTranslation from '@/utils/i18n/useTranslation';

export const useCurrencySymbol = () => {
  const { t_ns } = useTranslation(CURRENCIES);

  const combineCurrencySymbol = (
    cost: number | null,
    currencyCode: string | null = 'JPY'
  ): string => {
    if (!cost || !currencyCode) {
      return '';
    }
    const prefix = t_ns(`code.${currencyCode}.prefix`);
    const suffix = t_ns(`code.${currencyCode}.suffix`);
    return `${prefix}${new Intl.NumberFormat().format(cost)}${suffix}`;
  };

  const getCurrencySymbol = (currencyCode: string | null = 'JPY'): string => {
    const prefix = t_ns(`code.${currencyCode}.prefix`);
    const suffix = t_ns(`code.${currencyCode}.suffix`);
    return prefix !== '' ? prefix : suffix;
  };

  return { combineCurrencySymbol, getCurrencySymbol };
};
